import React from 'react'
import { Link, graphql } from 'gatsby'
import { mapEdgesToNodes } from '../utils/helpers';
import BlogPostPreview from '../components/blog-post-preview';
import SEO from '../components/seo'
import Breadcrumbs from '../components/breadcrumbs'

const BlogPostList = props => {
  const { data, errors } = props
  const {
    isFirst,
    isLast,
    prevPage,
    nextPage,
    postsPerPage,
    totalPages,
    totalPosts,
    currentPage,
    pagePath,
    currentCategory,
    allUniqueCategorySlugs,
    isRoot
  } = props.pageContext

  const blogNodes = data && data.blog && mapEdgesToNodes(data.blog)
  const category = data && data.category

  let categories = data && data.categories && mapEdgesToNodes(data.categories)
  categories = categories.filter(category => allUniqueCategorySlugs.indexOf(category.slug.current) !== -1)

  return (
    <div className='mb-24'>
      {errors && <SEO title='GraphQL Error' />}
      <Breadcrumbs crumbs={
        isRoot
          ? [{ title: 'Blogg' }]
          : [{ title: 'Blogg', slug: { current: 'blogg' }}, category]
      } />
      <div className='pb-5 md:pb-10 pt-16 lg:pt-24 bg-opacity-50 header-offset'>
        <div className='mx-5 md:mx-10 lg:mx-20 text-center'>
          <div className='border-b border-black-light pb-12 md:pb-32'>
            <div className='max-w-screen-xs mx-auto'>
              <h1 className='display-h1 text-black'>Bli bedre kjent med kroppen.</h1>
              <p className='lead'>Kjenn din kropp! Våre erfarne leger lærer deg mer om den fantastiske mannekroppen.</p>
            </div>
          </div>

          {categories && categories.length > 0 && (
            <div className='flex overflow-auto justify-start mb-4 mt-4 md:mt-8 -mx-5 px-5'>
              <div className='mr-8'>
                <Link to='/blogg' className={`
                  uppercase
                  font-bold
                  px-1
                  ${currentCategory === 'blogg' ? 'bg-signal': ''}
                  no-underline
                  text-sm
                `}>Alle</Link>
              </div>
              {categories.map(node => (
                <div className='mr-8' key={node.id}>
                  <Link
                    to={`/blogg/${node.slug.current}`}
                    className={`
                      uppercase
                      font-bold
                      text-sm
                      no-underline
                      px-1
                      ${node.slug.current === currentCategory ? 'bg-signal': ''}`}
                  >
                    {node.title}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {blogNodes && blogNodes.length > 0 ? (
        <div>
          <div className='mx-5 md:mx-10 lg:mx-20 grid md:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-16'>
            {blogNodes.map(node => (
              <BlogPostPreview className='w-full' key={node.id} {...node} />
            ))}
          </div>
          {totalPosts > postsPerPage && (
            <ul className='mt-24 flex flex-wrap justify-center no-underline text-primary'>
              {!isFirst ? (
                <li>
                  <Link className='p-4 no-underline' to={prevPage} rel="prev">
                    Forrige
                  </Link>
                </li>
              ) : (
                <span className="text-primary-light text-opacity-50">Forrige</span>
              )}
              {Array.from({ length: totalPages }, (_, i) => (
                <li key={`pagination-number-${i + 1}`}>
                  {currentPage == i + 1 ? (
                    <span className='p-4'>
                      {i + 1}
                    </span>
                  ) : (
                    <Link className='p-4' to={i === 0 ? `${pagePath}` : `${pagePath}/side/${i + 1}`}>
                      {i + 1}
                    </Link>
                  )}
                </li>
              ))}
              {!isLast ? (
                <li>
                  <Link className='p-4 no-underline' to={nextPage} rel="next">
                    Neste
                  </Link>
                </li>
              ) : (
                <span className="text-primary-light text-opacity-50">Neste</span>
              )}
            </ul>
          )}
        </div>
      ) : (
        <p>Fant ingen bloggposter</p>
      )}
    </div>
  )
}

export default BlogPostList

export const query = graphql`
  query GetBlogPosts(
    $limit: Int!,
    $skip: Int!,
    $category: SanityCategoryFilterInput = {},
    $slug: SanitySlugFilterInput = {}
  ) {
    blog: allSanityBlog(
      sort: { fields: _createdAt, order: DESC },
      limit: $limit,
      skip: $skip,
      filter: { category: $category }
    ) {
      edges {
        node {
          ...BlogPostPreview
        }
      }
    }

    category: sanityCategory(slug: $slug) {
      title
      slug {
        current
      }
    }

    categories: allSanityCategory(
      filter: {
        slug: { current: { ne: null } }
      }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`
